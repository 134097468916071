import { Link, useLocation } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { signOut } from '../lib/adminapi';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../context/AdminGlobalProvider';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect } from 'react';

function AdminHeader() {
    const location = useLocation();
    const navigate = useNavigate();
    const { creator, setCreator, setIsLoggedIn, isLoading } = useGlobalContext();

    useEffect(() => {
        if (isLoading) return;
        let timeoutId;

        const checkAuth = async () => {
            try {
                const token = localStorage.getItem('token'); // Replace AsyncStorage with localStorage
                console.log('Retrieved Token:', token);

                if (!creator || !token || !creator.isAdmin) {
                    window.alert('Session Expired or UNAUTHORIZED');
                    setIsLoggedIn(false)
                    navigate('/', { replace: true }); // Redirect to sign-in
                    return;
                }

                // Decode the token to check expiration
                const decoded = jwtDecode(token);
                console.log('Decoded Token:', decoded);

                const currentTime = Date.now() / 1000; // Current time in seconds
                console.log('Current Time (s):', currentTime);
                console.log('Token Expiration Time (s):', decoded.exp);

                if (decoded.exp < currentTime) {
                    // Token has already expired
                    localStorage.removeItem('token'); // Remove expired token
                    window.alert('Session Expired or UNAUTHORIZED');
                    setIsLoggedIn(false)
                    navigate('/', { replace: true }); // Redirect to sign-in
                } else {
                    // Calculate remaining time until token expires
                    const timeLeft = (decoded.exp - currentTime) * 1000; // Convert to milliseconds
                    console.log('Time left until token expires (ms):', timeLeft);

                    // Set a timeout to redirect the creator when the token expires
                    timeoutId = setTimeout(async () => {
                        localStorage.removeItem('token'); // Remove expired token
                        window.alert('Session Expired or UNAUTHORIZED');
                        setIsLoggedIn(false)
                        navigate('/', { replace: true }); // Redirect to sign-in
                    }, timeLeft);
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                window.alert('An unexpected error occurred. Please sign in again.');
                setIsLoggedIn(false)
                navigate('/', { replace: true });
            }
        };

        checkAuth();

        // Cleanup the timeout when the component unmounts or dependencies change
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [creator, navigate, setIsLoggedIn, isLoading]);

    const confirmSignOut = () => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, sign me out!',
        }).then((result) => {
            if (result.isConfirmed) {
                handlesignOut();
            }
        });
    };

    const handlesignOut = async () => {
        await signOut();
        localStorage.removeItem('token');
        localStorage.removeItem('creator');
        setCreator(null);
        setIsLoggedIn(false);
        navigate('/', { replace: true });
    };

    return (
        <header className="bg-gradient-to-r from-[#303261] to-[#221e3a] py-5 px-7">
            {/* Navbar */}
            <nav className="flex justify-between items-center">
                {/* Logo */}
                <div className="text-white text-2xl font-bold hidden md:block">
                    <Link to="/adminHome">
                        <img src="/NEW_LOGO.png" alt="Logo" />
                    </Link>
                </div>

                {/* Menu */}
                <ul className="flex space-x-10 text-white text-base md:text-2xl">
                    <li>
                        <Link
                            to="/adminHome"
                            className={`hover:text-[#574bfc] ${location.pathname === '/adminHome' ? 'text-[#574bfc]' : ''
                                }`}
                        >
                            Home
                        </Link>
                    </li>

                    <li>
                        <Link
                            to="/adminVentsProfile"
                            className={`hover:text-[#574bfc] ${location.pathname === '/adminVentsProfile' ? 'text-[#574bfc]' : ''
                                }`}
                        >
                            Vents
                        </Link>
                    </li>
                    {/* <li>
                        <Link
                            to="/requests"
                            className={`hover:text-[#574bfc] ${location.pathname === '/requests' ? 'text-[#574bfc]' : ''
                                }`}
                        >
                            Requests
                        </Link>
                    </li> */}
                    <li>
                        <Link
                            to="/onboard"
                            className={`hover:text-[#574bfc] ${location.pathname === '/onboard' ? 'text-[#574bfc]' : ''
                                }`}
                        >
                            Onboard
                        </Link>
                    </li>


                </ul>
                <p></p>
                <p></p>
                <p></p>
                <button
                    onClick={confirmSignOut}
                    className="text-white hover:text-[#574bfc]"
                    title="Sign Out"
                >
                    <FaSignOutAlt size={24} className="w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7" />
                </button>
            </nav>
        </header>
    );
}

export default AdminHeader;
