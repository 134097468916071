import React from 'react';
import Header2 from '../src/components/header2';
import Footer from '../src/components/footer';


function App() {
  return (
    <div className="bg-[#160e30] h-screen overflow-x-hidden ">
      <Header2 />

      <div
        className="h-full w-full bg-cover bg-center flex flex-col items-center justify-center"
        style={{
          backgroundImage: `url(/group-people-enjoying-holi-color.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="text-center">
          <h1 className="text-black text-2xl md:text-6xl mb-8 font-medium">
            Discover. <span className="mx-4">Dance.</span> Connect.
          </h1>
          <button
            onClick={() => window.location.href = "/contact"}
            className="bg-[#4954a5] text-white text-base md:text-3xl font-medium py-2 md:py-5 px-5 md:px-10 rounded-full shadow-lg hover:bg-[#574bfc] transition duration-300"
          >
            Become a Creator
          </button>
        </div>
      </div>
      <Footer />
    </div>

  );
}

export default App;
