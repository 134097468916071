import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';

const VentsAdminCard = ({ event, onOpenModal }) => {
    const { title, thumbnail, venue, date } = event;
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '';

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }); // "December"
        const year = date.getFullYear();

        return `${day} ${month}, ${year}`; // "11 December, 2024"
    };
    return (
        <div className="flex flex-col items-center px-4 mb-6">
            <div
                className="w-full h-96 rounded-3xl mt-6 relative cursor-pointer transition-transform transform hover:scale-105"
                onClick={() => onOpenModal(event)} // Call onOpenModal with the event data
            >
                {/* Background Image */}
                <img
                    src={thumbnail}
                    alt={title}
                    className="w-full h-full rounded-3xl opacity-50 object-cover"
                />

                {/* Overlay with event details */}
                <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center px-10 py-6 text-white">
                    {/* Title and Time */}
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="md:text-4xl text-lg font-semibold truncate ">{title}</h3>
                        <span className="md:text-lg text-sm">{formatDate(date)}</span>
                    </div>

                    {/* Venue and Location Icon */}
                    <div className="flex items-center">
                        <FaMapMarkerAlt className="md:w-8 md:h-8 w-4 h-4 mr-2" /> {/* Location Icon */}
                        <p className="md:text-xl text-sm truncate">{venue}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VentsAdminCard;
