
const API_URL = process.env.REACT_APP_API_URL;


export const getToken = async () => {
    try {
        const token = await localStorage.getItem('token');
        if (!token) throw new Error('No token found');
        return token;
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error;
    }
};

export const signIn = async (username, password) => {
    try {
        const response = await fetch(`${API_URL}/api/creators/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password }),
        });

        const data = await response.json();

        if (!response.ok) {
            return { success: false, error: data.error || 'Failed to sign in' };
        }

        await localStorage.setItem('token', data.token);

        return { success: true, data };
    } catch (error) {
        console.error('Error in signInCreator:', error);
        return { success: false, error: error.message || 'An unknown error occurred' };
    }
};


export const createPost = async (postData) => {
    try {
        const token = await getToken()
        const response = await fetch(`${API_URL}/api/creators/createpost`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include the JWT token
            },
            body: JSON.stringify(postData),
        });

        const contentType = response.headers.get('content-type');
        console.log('Response Status:', response.status);
        console.log('Response Content-Type:', contentType);

        if (contentType && contentType.includes('application/json')) {
            const data = await response.json();
            console.log('Response Data:', data);

            if (!response.ok) {
                return { success: false, error: data.error || 'Failed to create event' };
            }

            return { success: true, data };
        } else {
            const text = await response.text();
            console.error('Non-JSON response:', text);
            return { success: false, error: 'Unexpected server response.' };
        }
    } catch (error) {
        console.error('Error in createPost:', error);
        return { success: false, error: error.message || 'An unknown error occurred' };
    }
};

export const getCurrentCreator = async () => {
    try {
        const token = await getToken();
        if (!token) return null;
        const response = await fetch(`${API_URL}/api/creators/me`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 401) return null;
        const data = await response.json();
        if (!response.ok) throw new Error(data.error || 'Failed to fetch user');
        return data;
    } catch (error) {
        throw error;
    }
};

export const signOut = async () => {
    try {
        const token = await getToken();
        const response = await fetch(`${API_URL}/api/creators/signout`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.error || 'Failed to sign out');
        }
        return true;
    } catch (error) {
        throw error;
    }
};

export const getCreatorPosts = async (creatorId) => {
    try {

        const token = await getToken();

        const response = await fetch(`${API_URL}/api/creators/getcreatorposts/${creatorId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token if authentication is required
            },
        });

        if (!response.ok) throw new Error('Failed to fetch posts');

        const posts = await response.json(); // Parse response to JSON
        return posts; // Return the fetched posts
    } catch (error) {
        console.error('Error fetching all posts:', error);
        throw error; // Re-return the error to handle it in the UI
    }
};

export const getAllCreatorPosts = async () => {
    try {

        const token = await getToken();

        const response = await fetch(`${API_URL}/api/creators/getallcreatorposts`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token if authentication is required
            },
        });

        if (!response.ok) throw new Error('Failed to fetch posts');

        const posts = await response.json(); // Parse response to JSON
        return posts; // Return the fetched posts
    } catch (error) {
        console.error('Error fetching all posts:', error);
        throw error; // Re-return the error to handle it in the UI
    }
};

export async function getPresignedUrl(fileName, fileType) {
    try {
        const token = await getToken();
        const response = await fetch(`${API_URL}/api/creators/presigned-url`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ fileName, fileType }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to get presigned URL');
        }

        return await response.json(); // Contains url, fields, key
    } catch (error) {
        console.error('Error getting presigned URL:', error);
        throw error;
    }
}

// Function to upload the file to S3 using the pre-signed URL
export async function uploadFileToS3(url, formData) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            const responseText = await response.text();
            console.error('S3 upload failed:', response.status, response.statusText, responseText);
            throw new Error('Failed to upload file to S3');
        }

        return response;
    } catch (error) {
        console.error('Error uploading file to S3:', error);
        throw error;
    }
}
export const sendContactForm = async (email, title, message) => {
    try {
        const response = await fetch(`${API_URL}/api/creators/contact-form`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, title, message }),
        });

        const data = await response.json();

        if (!response.ok) {
            return {
                success: false,
                error: data.error || 'An error occurred while sending your message.',
            };
        }

        return { success: true, data };
    } catch (error) {
        console.error('Error in sendContactForm:', error);
        return { success: false, error: error.message || 'An unknown error occurred' };
    }
};


// adminapi.js

export const validateTicket = async (qrCode, eventId) => {
    try {
        const token = await getToken();
        const response = await fetch(`${API_URL}/api/tickets/validate-ticket`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ qrCode, eventId }),
        });

        const data = await response.json(); // Parse the JSON response

        if (!response.ok) {
            // Return the error status and message from the backend
            return { status: data.status, message: data.message };
        }

        return data; // Assuming the backend returns { status: 'success', message: '...' }
    } catch (error) {
        console.error('Error validating ticket:', error);
        throw error;
    }
};

export const getAttendees = async (eventId) => {
    try {
        const token = await getToken();
        const response = await fetch(`${API_URL}/api/creators/attendees/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include the JWT token
            },
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.error || 'Failed to fetch attendees');
        }

        const data = await response.json();
        return data.attendees;
    } catch (error) {
        console.error('Error fetching attendees:', error);
        throw error;
    }
};

// adminapi.js

export const changeTitle = async (newTitle, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changetitle/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ title: newTitle }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post title';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeTitle:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeVenue = async (newVenue, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changevenue/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ venue: newVenue }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post venue';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeVenue:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changePrice = async (newPrice, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/newprice/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ price: newPrice }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post Price';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changePrice:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeTickets = async (newTickets, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changetickets/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ tickets: newTickets }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post tickets';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeTickets:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeDjName = async (newDJ, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changeDJ/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ djname: newDJ }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post DJ';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeDjName:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeDate = async (newDate, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changeDate/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ date: newDate }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post Date';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeDate:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeTime = async (newTime, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changetime/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ time: newTime }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post time';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeTime:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeGenre = async (newGenre, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changegenre/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ genre: newGenre }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post Genre';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeGenre:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeCity = async (newCity, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changecity/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ city: newCity }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post City';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeCity:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeLocation = async (newLocation, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changelocation/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ location: newLocation }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post Location';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeLocation:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeState = async (newState, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changestate/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ state: newState }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post State';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeState:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeCountry = async (newCountry, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changecountry/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ country: newCountry }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post Country';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeCountry:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};


export const changeDescription = async (newDescription, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changedescription/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ description: newDescription }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post description';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changeDescription:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const changeThumbnail = async (imageUrl, eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/changethumbnail/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ thumbnail: imageUrl }) // Send new title
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to change Post thumbnail';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in changethumbnail:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const deletePost = async (eventId) => {
    try {
        const token = await getToken(); // Get the user token

        const response = await fetch(`${API_URL}/api/posts/deleteEvent/${eventId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },

        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to delete the event';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in deletePost:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};

export const CreateCreator = async (username, password) => {
    try {
        const token = await getToken(); // Get the admin token

        const response = await fetch(`${API_URL}/api/creators/createCreator`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Include token for authentication
            },
            body: JSON.stringify({ username, password }),
        });

        const data = await response.json();

        if (!response.ok) {
            const errorMessage = data.error || 'Failed to create creator';
            throw new Error(errorMessage);
        }

        return data; // Return success message or any other relevant data
    } catch (error) {
        console.error('Error in CreateCreator:', error);
        throw error; // Re-throw the error to handle it in the UI
    }
};