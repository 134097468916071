// context/AdminGlobalProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentCreator } from '../lib/adminapi';

const GlobalContext = createContext();
export const useGlobalContext = () => useContext(GlobalContext);

const AdminGlobalProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [creator, setCreator] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const storedToken = await localStorage.getItem('token');
                if (storedToken) {
                    setToken(storedToken);
                    const currentCreator = await getCurrentCreator();
                    if (currentCreator) {
                        setCreator(currentCreator);
                        setIsLoggedIn(true);
                    } else {
                        setIsLoggedIn(false);
                    }
                } else {
                    setIsLoggedIn(false);
                }
            } catch (error) {

                setIsLoggedIn(false);
            } finally {
                setIsLoading(false);
            }
        };
        initializeAuth();
    }, []);

    return (
        <GlobalContext.Provider
            value={{
                token,
                setToken,
                isLoggedIn,
                setIsLoggedIn,
                creator,
                setCreator,
                isLoading,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default AdminGlobalProvider;
