import React, { useState } from 'react';
import Header from '../components/header';
import Footer2 from '../components/footer2';
import { sendContactForm } from '../lib/adminapi';

function Contact2() {
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const sendForm = async (e) => {
        e.preventDefault();

        if (!email || !title || !message) {
            setError('All fields are required.');
            return;
        }

        try {
            const result = await sendContactForm(email, title, message);

            if (result.success) {
                setSuccess('Your message has been sent!');
                setEmail('');
                setTitle('');
                setMessage('');
                setError('');
            } else {
                setError(result.error || 'An error occurred while sending your message.');
            }
        } catch (err) {
            console.error('Error:', err);
            setError('An error occurred while sending your message.');
        }
    };

    return (
        <div className="bg-[white] min-h-screen overflow-x-hidden flex flex-col">
            <Header />

            <div className="flex-grow flex flex-col items-center justify-start mt-12 px-4">
                <div className="bg-white shadow-lg rounded-lg p-8 w-[90%] max-w-3xl">
                    <h1 className="text-[#303261] text-4xl md:text-6xl font-medium mb-6 text-center">
                        Contact Us
                    </h1>
                    <form onSubmit={sendForm} className="space-y-6">
                        {error && <p className="text-red-500">{error}</p>}
                        {success && <p className="text-green-500">{success}</p>}

                        <div className="flex flex-col">
                            <label className="text-[#303261] text-sm md:text-xl mb-2">
                                Your Email<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="border border-gray-300 rounded p-2 focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                required
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="text-[#303261] text-sm md:text-xl mb-2">
                                Title<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="border border-gray-300 rounded p-2 focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                required
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="text-[#303261] text-sm md:text-xl mb-2 ">
                                Message<span className="text-red-500">*</span>
                            </label>
                            <textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="border border-gray-300 rounded p-2 h-32 focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                required
                            ></textarea>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className="bg-[#4954a5] text-white text-xl font-medium py-2 px-6 rounded hover:bg-[#574bfc] transition duration-300"
                            >
                                Send Message
                            </button>
                        </div>

                    </form>
                </div>
            </div>

            <Footer2 />
        </div>
    );
}

export default Contact2;
