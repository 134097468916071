// components/AttendeesModal.js
import React, { useEffect, useState } from 'react';
import { getAttendees } from '../lib/adminapi'; // Import the getAttendees function
import * as XLSX from 'xlsx'; // Import XLSX library

const AttendeesModal = ({ eventId, eventTitle, onClose }) => {
    const [attendees, setAttendees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAttendees = async () => {
            try {
                const attendeesData = await getAttendees(eventId);
                setAttendees(attendeesData);
            } catch (err) {
                console.error("Error fetching attendees:", err);
                setError("Failed to load attendees.");
            } finally {
                setLoading(false);
            }
        };

        fetchAttendees();
    }, [eventId]);

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleExtract = () => {
        if (attendees.length === 0) return;

        // Helper function to calculate age from DOB
        const calculateAge = (dob) => {
            const birthDate = new Date(dob);
            const today = new Date();

            if (isNaN(birthDate)) {
                return 'Invalid DOB';
            }

            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
            const dayDifference = today.getDate() - birthDate.getDate();

            // Adjust age if birthday hasn't occurred yet this year
            if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
                age--;
            }

            return age;
        };

        // Prepare the data for the Excel sheet
        const data = attendees.map(({ name, email, dob, ticketsBought }) => ({
            Name: name,
            Email: email,
            Age: calculateAge(dob), // Calculate age here
            'Tickets Bought': ticketsBought,
        }));

        // Create a worksheet and a workbook
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendees');

        // Generate the Excel file and trigger a download
        XLSX.writeFile(workbook, `Attendees_Event_${eventTitle}.xlsx`);
    };



    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            onClick={handleBackgroundClick}
        >
            <div className="bg-[#303261] p-6 rounded-xl w-full max-w-md relative shadow-lg">

                <h2 className="text-2xl font-semibold text-white mb-4">Attendees</h2>
                {loading ? (
                    <p className="text-white">Loading...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : attendees.length > 0 ? (
                    <div>
                        <div className="md:max-h-80 max-h-40 overflow-y-auto mb-4">
                            {attendees.map(user => {
                                const calculateAge = (dob) => {
                                    const birthDate = new Date(dob);
                                    const today = new Date();

                                    if (isNaN(birthDate)) {
                                        console.error('Invalid Date of Birth:', dob);
                                        return null;
                                    }

                                    let age = today.getFullYear() - birthDate.getFullYear();
                                    const monthDifference = today.getMonth() - birthDate.getMonth();
                                    const dayDifference = today.getDate() - birthDate.getDate();

                                    // Adjust age if birthday hasn't occurred yet this year
                                    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
                                        age--;
                                    }

                                    return age;
                                };

                                const age = calculateAge(user.dob);

                                return (
                                    <div key={user.email} className="flex items-center mb-4">
                                        <div>
                                            <p className="text-white font-medium">{user.name}</p>
                                            <p className="text-gray-300 text-sm">{user.email}</p>
                                            <p className="text-gray-300 text-sm">Age: {age !== null ? age : 'Unknown'}</p>
                                            <p className="text-gray-300 text-sm">Tickets Bought: {user.ticketsBought}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <button
                            className="bg-[#4954a5] hover:bg-[#574bfc] text-white font-bold py-2 px-4 rounded w-full"
                            onClick={handleExtract}
                        >
                            Extract to Excel
                        </button>
                    </div>

                ) : (
                    <p className="text-white">No attendees found.</p>

                )}
            </div>
        </div>
    );
};

export default AttendeesModal;
