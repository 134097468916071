// components/Onboard.js

import AdminHeader from '../components/adminHeader';
import React, { useState } from 'react';
import { CreateCreator } from '../lib/adminapi'; // Import from adminapi
import Swal from 'sweetalert2';

function Onboard() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !password) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Both fields are required!',
            });
            return;
        }

        try {
            const response = await CreateCreator(username, password);
            if (response.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Creation Successful!',
                    timer: 2000,
                    showConfirmButton: false,
                });
                // Optionally reset the form
                setUsername('');
                setPassword('');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Creation Failed!',
                    text: response.error || 'Unknown error occurred',
                });
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: `An error occurred: ${error.message}`,
            });
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='bg-[#211d3a] min-h-screen overflow-x-hidden '>
            <AdminHeader />

            <div className="flex items-center justify-center py-12">
                <div className="bg-[#303261] p-8 rounded-lg md:w-full max-w-md">
                    <h1 className="text-3xl text-white mb-6 text-center">Create Creator</h1>

                    <form onSubmit={handleSubmit}>
                        {/* Username Field */}
                        <div className="mb-4">
                            <input
                                type="text"
                                placeholder="Email"
                                id="username"
                                className="mt-1 w-full px-3 py-3 rounded-full bg-[#3c395d] text-white placeholder-white focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>

                        {/* Password Field with Eye Icon */}
                        <div className="mb-6 relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                id="password"
                                className="mt-1 w-full px-3 py-3 rounded-full bg-[#3c395d] text-white placeholder-white focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            {/* Eye Icon */}
                            <div
                                onClick={togglePasswordVisibility}
                                className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                            >
                                {showPassword ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-white"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M13.875 18.825C12.32 19.355 10.68 19.355 9.125 18.825M12 2.625a9 9 0 100 18 9 9 0 000-18zm1.477 13.107A3 3 0 118.04 8.293"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-white"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M15 12A3 3 0 119 12a3 3 0 016 0zm6-1.5A9 9 0 005 12a9 9 0 0016 0z"
                                        />
                                    </svg>
                                )}
                            </div>
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="w-full bg-gradient-to-r from-[#4548ec] to-[#9a51f5] text-white text-xl py-4 rounded-full"
                        >
                            Create
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Onboard;
