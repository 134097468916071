// Header2.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getCurrentCreator, signIn } from '../lib/adminapi';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../context/AdminGlobalProvider';
import Swal from 'sweetalert2';

function Header2() {
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();
    const { isLoading, isLoggedIn, creator, setCreator, setIsLoggedIn } = useGlobalContext();

    // Use useEffect to navigate based on user role
    useEffect(() => {
        if (!isLoading && isLoggedIn) {
            if (creator && creator.isAdmin) {
                navigate('/adminHome');
            } else {
                navigate('/home');
            }
        }
    }, [isLoading, isLoggedIn, creator, navigate]);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !password) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Both fields are required!',
            });
            return;
        }

        try {
            const response = await signIn(username, password);
            if (response.success) {
                const result = await getCurrentCreator();
                setCreator(result);
                setIsLoggedIn(true);

                // Navigate based on user role
                if (result.isAdmin) {
                    navigate('/adminHome');
                } else {
                    navigate('/home');
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Login Successful!',
                    text: 'Welcome to the dashboard',
                    timer: 2000,
                    showConfirmButton: false,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Login Failed!',
                    text: response.error || 'Unknown error occurred',
                });
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: `An error occurred during login: ${error.message}`,
            });
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <header className="bg-gradient-to-r from-[#303261] to-[#221e3a] py-5 px-7">
            {/* Navbar */}
            <nav className="flex justify-between items-center">
                {/* Logo */}
                <div className="text-white text-2xl font-bold hidden md:block">
                    <Link to="/">
                        <img src="/NEW_LOGO.png" alt="Logo" />
                    </Link>
                </div>

                {/* Menu */}
                <ul className="flex space-x-10 text-white text-base md:text-2xl">
                    <li>
                        <Link
                            to="/aboutUs"
                            className={`hover:text-[#574bfc] ${location.pathname === '/aboutUs' ? 'text-[#574bfc]' : ''
                                }`}
                        >
                            About
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/contact"
                            className={`hover:text-[#574bfc] ${location.pathname === '/contact' ? 'text-[#574bfc]' : ''
                                }`}
                        >
                            Contact
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/faqs"
                            className={`hover:text-[#574bfc] ${location.pathname === '/faqs' ? 'text-[#574bfc]' : ''
                                }`}
                        >
                            FAQs
                        </Link>
                    </li>
                </ul>
                <p>

                </p>
                <p></p>

                <button
                    type="button"
                    onClick={() => setIsModalOpen(true)}
                    className="border-2 border-[white] text-white text-base md:text-xl py-2 px-4 md:px-14 rounded-full hover:text-[#574bfc] hover:border-[#574bfc]"
                >
                    Log In
                </button>
            </nav>

            {/* Modal */}
            {isModalOpen && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    onClick={() => setIsModalOpen(false)}
                >
                    <div
                        className="bg-[#303261] p-6 rounded-lg  w-full max-w-md h-auto"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Modal content */}
                        <h1 className="text-3xl text-white mb-6 text-center">Log In</h1>

                        <form onSubmit={handleSubmit}>
                            {/* Username Field */}
                            <div className="mb-4">
                                <input
                                    type="text"
                                    placeholder="Email"
                                    id="username"
                                    className="mt-1 w-full px-3 py-3 rounded-full bg-[#3c395d] text-white placeholder-white focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>

                            {/* Password Field with Eye Icon */}
                            <div className="mb-6 relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    id="password"
                                    className="mt-1 w-full px-3 py-3 rounded-full bg-[#3c395d] text-white placeholder-white focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                {/* Eye Icon */}
                                <div
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                                >
                                    {showPassword ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-white"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M13.875 18.825C12.32 19.355 10.68 19.355 9.125 18.825M12 2.625a9 9 0 100 18 9 9 0 000-18zm1.477 13.107A3 3 0 118.04 8.293"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-white"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M15 12A3 3 0 119 12a3 3 0 016 0zm6-1.5A9 9 0 005 12a9 9 0 0016 0z"
                                            />
                                        </svg>
                                    )}
                                </div>
                            </div>

                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="w-full bg-gradient-to-r from-[#4548ec] to-[#9a51f5] text-white text-xl py-4 rounded-full"
                            >
                                Continue
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </header>
    );
}

export default Header2;
