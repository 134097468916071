import React, { useState, useRef, useEffect } from 'react';
import QrScanner from 'react-qr-barcode-scanner';
import { validateTicket } from '../lib/adminapi';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

const ScanModal = ({ event, isOpen, onClose }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isScanning, setIsScanning] = useState(false);
    const scannerRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setIsScanning(true);
        } else {
            setIsScanning(false);
        }
    }, [isOpen]);

    const handleError = (error) => {
        console.error('QR Scanner Error:', error);
        Swal.fire({
            title: 'Error!',
            text: `Camera error: ${error.message || error}`,
            icon: 'error',
            confirmButtonText: 'OK',
        });
    };

    const handleScan = async (error, result) => {
        if (isProcessing) return;

        if (result) {
            setIsProcessing(true);
            setIsScanning(false); // Stop scanning immediately

            const cleanedQrCode = result.text.replace(/['"]/g, '').trim();

            try {
                const response = await validateTicket(cleanedQrCode, event.id);

                await Swal.fire({
                    title: response.status === 'success' ? 'Success!' : 'Error!',
                    text: response.message,
                    icon: response.status === 'success' ? 'success' : 'error',
                    confirmButtonText: 'OK',
                });
            } catch (error) {
                await Swal.fire({
                    title: 'Error!',
                    text: `Error validating ticket: ${error.message}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            } finally {
                setIsProcessing(false);
                onClose(); // Close the modal after processing
            }
        } else if (error) {
            console.error('Scanning error:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-[#262549] rounded-lg p-6 w-11/12 md:w-1/2 lg:w-1/3">
                <h2 className="text-2xl text-white mb-4">Scan Ticket for {event.title}</h2>
                <div style={{ width: '100%' }}>
                    {isScanning && (
                        <QrScanner
                            ref={scannerRef}
                            onError={handleError}
                            onUpdate={handleScan}
                            facingMode="environment"
                            style={{ width: '100%' }}
                        />
                    )}
                </div>
                <div className="flex justify-center mt-4">
                    <button
                        className="mt-4 px-4 py-2 bg-[#4954a5] hover:bg-[#574bfc] text-white rounded"
                        onClick={onClose}
                        disabled={isProcessing}
                    >
                        {isProcessing ? 'Processing...' : 'Close'}
                    </button>
                </div>
            </div>
        </div>
    );
};

ScanModal.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ScanModal;
