import Header from '../components/header';
import Footer2 from '../components/footer2';
import React from 'react';

function Home() {
    return (
        <div className="bg-[#160e30] h-screen overflow-x-hidden">
            <Header />
            <div
                className="h-full w-full bg-cover bg-center flex flex-col items-center px-4 sm:px-40 justify-center"
                style={{
                    backgroundImage: `url(/group-people-dancing-holi-colors.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="text-center">
                    <h1 className="text-black text-xl md:text-6xl mb-4 md:mb-8 font-bold">
                        New here?
                    </h1>
                    <h1 className="text-black text-lg md:text-6xl mb-4 md:mb-8 font-medium">
                        Start by creating your first Vent!
                    </h1>
                    <button
                        onClick={() => (window.location.href = '/createEvent')}
                        className="bg-[#4954a5] text-white text-sm md:text-3xl font-medium py-2 md:py-5 px-4 md:px-10 rounded-full shadow-lg hover:bg-[#574bfc] transition duration-300"
                    >
                        Create a Vent
                    </button>
                </div>
            </div>
            <Footer2 />
        </div>
    );
}

export default Home;
