// AppWrapper.js
import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const AppWrapperAdmin = ({ children }) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup the event listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    if (!isOnline) {
        // Show loading state when there is no network
        return (
            <div style={styles.wrapper}>
                <div style={styles.container}>
                    <ClipLoader size={50} color="#FFFFFF" />
                    <p style={styles.text}>Check your network connection</p>
                </div>
            </div>
        );
    }

    // Render the app when there is network connectivity
    return children;
};

const styles = {
    wrapper: {
        height: '100vh',
        width: '100vw',
        background: 'linear-gradient(135deg, #211a48, #291c3e)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        textAlign: 'center',
    },
    text: {
        color: 'white',
        fontSize: '18px',
        marginTop: '16px',
    },
};

export default AppWrapperAdmin;
