import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import { getCreatorPosts } from '../lib/adminapi';
import { useGlobalContext } from '../context/AdminGlobalProvider';
import VentsCard from '../components/ventscard';
import EmptyState from '../components/emptystate';
import ScanModal from '../components/scanModal';
import Footer2 from '../components/footer2';

// Import the modal component

function TicketScanner() {
    const { setCreator, creator, } = useGlobalContext();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(null); // State to manage selected event
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility



    useEffect(() => {
        const storedCreator = localStorage.getItem('creator');
        if (!creator && storedCreator) {
            setCreator(JSON.parse(storedCreator));
        }

        const fetchPosts = async () => {
            if (creator?.id) {
                try {
                    const result = await getCreatorPosts(creator.id);
                    setPosts(result);
                } catch (error) {
                    console.error("Error fetching posts:", error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchPosts();
    }, [creator, setCreator]);



    const openModal = (event) => {
        setSelectedEvent(event);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedEvent(null);
    };

    return (
        <div className="flex flex-col bg-[#211d3a] h-screen overflow-x-hidden">
            <Header />
            <div className="flex-grow flex-col flex justify-center items-center bg-[#211d3a8] p-4 ">


                <div className="w-full flex flex-col items-center ">
                    <h2 className="text-white text-3xl ">Select the event you want to scan tickets for</h2>
                    {loading ? (
                        <p className="text-white">Loading events...</p>
                    ) : posts.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-6 pb-16">
                            {posts.map((item) => (
                                <VentsCard key={item.id} event={item} onOpenModal={openModal} />
                            ))}
                        </div>
                    ) : (
                        <EmptyState title="No Events Found" />
                    )}
                </div>
            </div>
            {/* Modal for event details */}
            {isModalOpen && (
                <ScanModal event={selectedEvent} isOpen={isModalOpen} onClose={closeModal} />
            )}
            <Footer2 />
        </div>
    );
}

export default TicketScanner;

