import React from 'react';
import Header from '../components/header';
import Footer2 from '../components/footer2';

function Terms2() {
    return (
        <div className="bg-[white] h-screen overflow-x-hidden flex flex-col">
            <Header />

            <div className="flex-grow flex flex-col items-center justify-start mt-12 px-4">
                <div className="bg-white shadow-lg rounded-lg p-8 w-[90%] max-w-3xl">
                    <h1 className="text-[#303261] text-4xl md:text-6xl font-medium mb-6 text-center">
                        Terms and Conditions
                    </h1>
                    <div className="space-y-6">
                        {/* General Terms */}
                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>General Terms</strong>
                            <p>
                                By using the Vent application, you agree to abide by these terms and conditions. Vent reserves the right to update these terms at any time without prior notice. Continued use of the app signifies acceptance of any updated terms.
                            </p>
                        </div>

                        {/* Ticket Purchases */}
                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>Ticket Purchases</strong>
                            <p>
                                Each ticket purchased through Vent includes a unique, non-transferable code. Tickets are valid only for the specific event for which they were purchased and cannot be reused or refunded. Users are responsible for safeguarding their ticket codes.
                            </p>
                        </div>

                        {/* Limitation of Liability */}
                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>Limitation of Liability</strong>
                            <p>
                                Vent is not liable for any damages or losses associated with event attendance, ticket misuse, or user conduct within the app.
                            </p>
                        </div>

                        {/* Refund Policy */}
                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>Refund Policy</strong>
                            <p></p>
                            <div className="ml-4">
                                <strong>Non-Refundable Policy</strong>
                                <p>
                                    All ticket sales are final. Due to the nature of our ticketing system, each ticket includes a unique code that cannot be reused, exchanged, or refunded under any circumstances. Users are advised to carefully review event details before purchasing.
                                </p>
                            </div>
                        </div>

                        {/* Cancellation Policy */}
                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>Cancellation Policy</strong>
                            <p></p>
                            <div className="ml-4">
                                <strong>Organizer Cancellations</strong>
                                <p>
                                    In the event of an organizer-initiated cancellation, Vent will notify users and work with the organizer to reschedule the event. No refunds will be provided unless specifically arranged by the event organizer.
                                </p>
                            </div>
                        </div>

                        {/* Delivery Policy & Method */}
                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>Delivery Policy & Method</strong>
                            <p></p>
                            <div className="ml-4">
                                <strong>Ticket Delivery</strong>
                                <p>
                                    Upon purchase, a unique ticket code will be generated and delivered digitally within the app. Users can access their tickets anytime under the “My Tickets” section.
                                </p>
                                <strong>Redemption</strong>
                                <p>
                                    Users must present their unique ticket code at the event for entry. The code is valid only once and cannot be transferred or reused.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer2 />
        </div>
    );
}

export default Terms2;
