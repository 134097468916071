import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CreateEvent from './dashboard/createEvent';
import Home from './dashboard/home';
import AdminProfile from './dashboard/adminProfile'; // Corrected import
import TicketScanner from './dashboard/scanner'; // Corrected import
import AdminGlobalProvider from './context/AdminGlobalProvider';
import AppWrapperAdmin from './AppWrapperAdmin';
import AboutUs from './dashboard/aboutUs';
import Terms from './dashboard/terms';
import Contact from './dashboard/contact';
import Privacy from './dashboard/privacy';
import FAQS from './dashboard/faqs';
import AboutUs2 from './dashboard/aboutUs2';
import Terms2 from './dashboard/terms2';
import Contact2 from './dashboard/contact2';
import Privacy2 from './dashboard/privacy2';
import FAQS2 from './dashboard/faqs2';
import AdminHome from './dashboard/adminHome';
import AdminVentsProfile from './dashboard/adminVentsProfile';
import Onboard from './dashboard/onboard';
import Requests from './dashboard/requests';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppWrapperAdmin>
      <AdminGlobalProvider>
        <Router>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/createEvent" element={<CreateEvent />} />
            <Route path="/home" element={<Home />} />
            <Route path="/adminProfile" element={<AdminProfile />} />
            <Route path="/scanner" element={<TicketScanner />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/faqs" element={<FAQS />} />
            <Route path="/terms2" element={<Terms2 />} />
            <Route path="/contact2" element={<Contact2 />} />
            <Route path="/privacy2" element={<Privacy2 />} />
            <Route path="/aboutUs2" element={<AboutUs2 />} />
            <Route path="/faqs2" element={<FAQS2 />} />
            <Route path="/adminHome" element={<AdminHome />} />
            <Route path="/adminVentsProfile" element={<AdminVentsProfile />} />
            <Route path="/onboard" element={<Onboard />} />
            <Route path="/requests" element={<Requests />} />





          </Routes>
        </Router>
      </AdminGlobalProvider>
    </AppWrapperAdmin>
  </React.StrictMode>
);

reportWebVitals();
