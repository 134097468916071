import AdminHeader from '../components/adminHeader';
import React from 'react';

function AdminHome() {


    return (
        <div className='bg-[#160e30] h-screen overflow-hidden '>
            <AdminHeader />
            <div
                className="h-full w-full bg-cover bg-center flex flex-col px-40 justify-center"
                style={{
                    backgroundImage: `url(/group-people-enjoying-holi-color.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >

            </div>

        </div>
    );
}

export default AdminHome;
