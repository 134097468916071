import React from 'react';
import {
    FaInstagram,
    FaLinkedin,
    FaFacebook,
} from 'react-icons/fa';
import { CiMail } from "react-icons/ci";
import { Link } from 'react-router-dom';
import { BsTwitterX } from "react-icons/bs";

const Footer2 = () => {
    return (
        <footer className="bg-gradient-to-r from-[#303261] to-[#221e3a] text-white py-10">
            <div className="container mx-auto px-4 sm:px-8 lg:px-20">
                <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
                    {/* Logo and Contact Info */}
                    <div className="mb-8 lg:mb-0">
                        <Link to="/home">
                            <img src="/NEW_LOGO.png" alt="Vent Logo" className=" md:h-14 h-12 mb-4" />
                        </Link>
                        <h3 className="md:text-lg text-base font-semibold mb-4 ">Have a question?</h3>
                        <div className="flex flex-col sm:flex-row sm:space-x-6 items-start sm:items-center">
                            <div className="flex items-center mb-2 sm:mb-0">
                                <CiMail className="w-8 h-8 mr-2" />
                                <span className="text-base sm:text-lg font-medium">
                                    <a href="mailto:support@v-e-n-t.com" className="hover:text-[#574bfc]">
                                        Contact us at support@v-e-n-t.com
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Social Media Icons */}
                    <div className="flex space-x-6 lg:mt-0">
                        <a
                            href="https://www.instagram.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-[#574bfc]"
                        >
                            <FaInstagram className="w-6 h-6" />
                        </a>
                        <a
                            href="https://www.twitter.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-[#574bfc]"
                        >
                            <BsTwitterX className="w-6 h-6" />
                        </a>
                        <a
                            href="https://www.linkedin.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-[#574bfc]"
                        >
                            <FaLinkedin className="w-6 h-6" />
                        </a>
                        <a
                            href="https://www.facebook.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-[#574bfc]"
                        >
                            <FaFacebook className="w-6 h-6" />
                        </a>
                    </div>
                </div>

                {/* Footer Links */}
                <div className="border-t border-white mt-8 pt-4 flex flex-col lg:flex-row justify-between text-sm sm:text-base font-medium">
                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-6 mb-4 lg:mb-0">
                        <Link
                            to="/aboutUs2"
                            className={`hover:text-[#574bfc]`}
                        >
                            About Us
                        </Link>
                        <Link
                            to="/contact2"
                            className={`hover:text-[#574bfc]`}
                        >
                            Contact Us
                        </Link>
                        <Link
                            to="/faqs2"
                            className={`hover:text-[#574bfc]`}
                        >
                            FAQs
                        </Link>
                        <Link
                            to="/privacy2"
                            className={`hover:text-[#574bfc]`}
                        >
                            Privacy Policy
                        </Link>
                        <Link
                            to="/terms2"
                            className={`hover:text-[#574bfc]`}
                        >
                            Terms & Conditions
                        </Link>
                    </div>
                    <div>&copy; 2024, All Rights Reserved</div>
                </div>
            </div>
        </footer>
    );
};

export default Footer2;
