import React from 'react';

const EmptyState = ({ title }) => {
    return (
        <div className="flex flex-col justify-center items-center px-4">
            <img
                src="/emptynew.png" // Update this with the actual path to your empty image
                alt="Empty"
                className="w-full h-auto max-w-md" // Adjust max-width for responsiveness
            />
            <p className="text-xl text-center font-semibold text-white ">
                {title}
            </p>
        </div>
    );
}

export default EmptyState;
