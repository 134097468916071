import React from 'react';
import Header2 from '../components/header2';
import Footer from '../components/footer';

function FAQS() {
    return (
        <div className="bg-[white] h-screen overflow-x-hidden flex flex-col">
            <Header2 />

            <div className="flex-grow flex flex-col items-center justify-start mt-12 px-4">
                <div className="bg-white shadow-lg rounded-lg p-8 w-[90%] max-w-3xl">
                    <h1 className="text-[#303261] text-4xl md:text-6xl font-medium mb-6 text-center">
                        Frequently Asked Questions
                    </h1>
                    <div className="space-y-6">
                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>1. How do I create an event on Vent?</strong>
                            <p>
                                Creating an event is simple! Log in, navigate to the "Create a Vent" section, and fill in the details such as title, venue, date, time, ticket price, and description. Upload an image and choose the location on the map to finalize.
                            </p>
                        </div>

                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>2. Can I see who else is attending an event?</strong>
                            <p>
                                Yes, Vent allows you to view a list of attendees. Explore their interests and see who might share your vibe!
                            </p>
                        </div>


                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>3. Is Vent free to use?</strong>
                            <p>
                                Signing up and discovering events is free. Event organizers may set ticket prices, which you can pay directly through the app.
                            </p>
                        </div>

                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>4. What types of events are on Vent?</strong>
                            <p>
                                Vent offers a diverse range of events to match every preference.
                            </p>
                        </div>

                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>5. How do I buy tickets?</strong>
                            <p>
                                Find your desired event, click "Buy Ticket," and proceed to checkout. You can pay online.
                            </p>
                        </div>


                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>6. What if I don’t receive a ticket after purchase?</strong>
                            <p>
                                Contact us immediately at  <a href="mailto:support@v-e-n-t.com" className="hover:text-[#574bfc]">
                                    support@v-e-n-t.com
                                </a>
                            </p>
                        </div>


                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default FAQS;
