import AdminHeader from '../components/adminHeader';
import React from 'react';

function Requests() {


    return (
        <div className='bg-[#211d3a] h-screen overflow-x-hidden '>
            <AdminHeader />

            <div >

            </div>
        </div>
    );
}

export default Requests;
