import React from 'react';
import Header2 from '../components/header2';
import Footer from '../components/footer';

function AboutUs() {
    return (
        <div className="bg-[white] h-screen overflow-x-hidden flex flex-col">
            <Header2 />

            <div className="flex-grow flex flex-col items-center justify-start mt-12">
                <div className="rounded-lg shadow-lg p-8 w-[90%] max-w-3xl text-center">
                    <h1 className="text-[#303261] text-4xl md:text-6xl font-medium mb-4">
                        About Us
                    </h1>
                    <p className="text-[#303261] text-sm md:text-xl font-medium leading-relaxed">
                        At Vent, we believe in more than just attending events, we believe in discovering unique experiences and forging connections that last. Whether it's a vibrant music festival, an intimate acoustic night, or an electrifying rave, Vent is your ultimate platform to explore and engage with the event scene.
                        <br /><br />
                        Our mission is to make event discovery seamless and exciting. Dive into our curated list of events, tailored to your preferences, and take the first step toward unforgettable memories. What sets us apart is the power to connect, you can see who else is attending, making it easier to find like-minded people who share your interests.
                        <br /><br />
                        However, we value privacy and organic connections. While you can view profiles of other attendees, communication happens outside the app through social media links. This ensures meaningful interactions while keeping the event community safe and enjoyable. At Vent, you're not just discovering events; you're connecting with a community.
                        <br /><br />
                        Let’s <strong>Discover. Dance. Connect.</strong> together!
                    </p>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default AboutUs;
