import React from 'react';
import Header2 from '../components/header2';
import Footer from '../components/footer';

function Privacy() {
    return (
        <div className="bg-[white] h-screen overflow-x-hidden flex flex-col">
            <Header2 />

            <div className="flex-grow flex flex-col items-center justify-start mt-12 px-4">
                <div className="bg-white shadow-lg rounded-lg p-8 w-[90%] max-w-3xl">
                    <h1 className="text-[#303261] text-4xl md:text-6xl font-medium mb-6 text-center">
                        Privacy Policy
                    </h1>
                    <div className="space-y-6">
                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>Data Collection</strong>
                            <p>
                                Vent collects user information such as name, contact details, and payment information solely for account management, ticketing, and communication purposes. This data is stored securely and is used to provide users with event details, ticket codes, and app support.                            </p>
                        </div>

                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>Data Protection</strong>
                            <p>
                                Vent is committed to protecting your privacy. Data security measures, including HTTPS and TLS 1.2 or higher, are in place to protect user information.                            </p>
                        </div>


                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong>User Rights</strong>
                            <p>
                                Users have the right to request access to or deletion of their data. Requests can be made via the app's support section.                            </p>
                        </div>

                        <div className="text-[#303261] text-sm md:text-xl leading-relaxed">
                            <strong> Data Sharing</strong>
                            <p>
                                User data is not shared with third parties except as required to process payments or comply with legal requirements.                            </p>
                        </div>



                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Privacy;
